<template>
  <div>
    <CoolLightBox
      :items="images"
      :index="indexImage"
      @close="indexImage = null"
    ></CoolLightBox>
    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      :change-columns-by-client="true"
      @reload="updateTable"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refOtherPayments"
          small
          no-provider-filtering
          :items="myProvider"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client_name)="data">
            <span v-if="moduleId == 16">{{data.item.client_name}}</span>
            <view-client-details :client-information="data.item" v-else />
          </template>
          <template #cell(date)="data">
            {{ data.item.date | myGlobal }}
          </template>
          <template #cell(amount)="data">
            $ {{ data.item.amount | currency }}
          </template>
          <template #cell(requested_by)="data">
            {{ data.item.request_first_name }} {{ data.item.request_last_name }}
            <br />
            {{ data.item.created_at | myGlobal }}
          </template>
          <template #cell(evaluated_by)="data">
            {{ data.item.evaluated_first_name }}
            {{ data.item.evaluated_last_name }}
            <br />
            {{ data.item.evaluated_at | myGlobal }}
          </template>
          <template #cell(transaction_id)="data">
            {{ data.item.transaction_number }}
          </template>
          <template #cell(evidence)="data">
            <b-avatar
              rounded="sm"
              :src="data.item.evidence"
              button
              @click="openEvidence(data.item.evidence)"
            ></b-avatar>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <template v-if="status == 1">
                <b-button
                  class="mx-1"
                  variant="primary"
                  @click="approvePay(data.item)"
                  size="sm"
                >
                  <feather-icon icon="ThumbsUpIcon" size="15" />
                </b-button>

                <b-button
                  variant="danger"
                  size="sm"
                  @click="disapprovePay(data.item)"
                >
                  <feather-icon icon="ThumbsDownIcon" size="15" />
                </b-button>
              </template>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import OtherPaymentsService from "../other-payments.service";
import fields from "./other-payments.fields";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import { mapGetters } from "vuex";
import TrackingModal from "./TrackingModal.vue";
import ViewClientDetails from "@/views/ce-digital/sub-modules/financial/components/ViewClientDetails.vue"
export default {
  props: {
    status: {
      type: Number,
      default: 1,
    },
  },
  components: {
    CoolLightBox,
    TrackingModalOthers: TrackingModal,
    ViewClientDetails
  },
  mounted() {
    if (this.status == 1) {
      this.fields[7].visible = false;
      if (this.moduleId == 16) {
        this.fields[8].visible = true;
        this.fields[6].visible = true;
      } else {
        this.fields[8].visible = false;
        this.fields[6].visible = false;
      }
    } else if (this.status == 2) {
      this.fields[6].visible = true;
      this.fields[7].visible = true;
      this.fields[7].label = "Approved by";
      this.fields[8].visible = false;
    } else if (this.status == 3) {
      this.fields[6].visible = false;
      this.fields[7].visible = true;
      this.fields[7].label = "Rejected by";
      this.fields[8].visible = false;
    }
  },
  data() {
    return {
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      endPage: null,
      nextPage: null,
      toPage: null,
      totalRows: 0,
      payments: [],
      fields,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Account...",
        model: "",
      },
      isBusy: false,
      sortBy: "created_at",
      sortDesc: true,
      images: [],
      indexImage: null,
      selectedOtherPaymentId: null,
      openTrackingOtherPayment: false,
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async myProvider(ctx) {
      try {
        const params = {
          campo: this.filterPrincipal.model,
          orderBy: ctx.sortBy,
          order: ctx.sortDesc ? "desc" : "asc",
          perPage: ctx.perPage,
          page: ctx.currentPage,
          from: null,
          to: null,
          status: this.status,
        };
        const { data } =
          await OtherPaymentsService.getOtherManualCeDigitalPayments(params);
        const {
          data: paymentsT,
          total,
          from,
          current_page,
          per_page,
          last_page,
          to,
        } = data;
        this.payments = paymentsT;
        this.startPage = from;
        this.paginate.currentPage = current_page;
        this.paginate.perPage = per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = last_page;
        this.totalRows = total;
        this.toPage = to;
        return this.payments || [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    updateTable() {
      this.$refs.refOtherPayments.refresh();
    },
    openEvidence(evidence) {
      this.images = [evidence];
      this.indexImage = 0;
    },
    async approvePay(item) {
      try {
        const result = await this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, approve it!",
        });
        if (result.isConfirmed) {
          await OtherPaymentsService.approveOtherManualCeDigitalPayments({
            id: item.id,
            approved_by: this.currentUser.user_id,
            transaction_id: item.transaction_number,
          });
          this.updateTable();
        }
      } catch (error) {
        console.error(error);
        this.$swal("Error!", "Something went wrong", "error");
      }
    },
    async disapprovePay(item) {
      try {
        const result = await this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, disapprove it!",
        });
        if (result.isConfirmed) {
          await OtherPaymentsService.disapproveOtherManualCeDigitalPayments({
            id: item.id,
            disapproved_by: this.currentUser.user_id,
          });
          this.updateTable();
        }
      } catch (error) {
        console.error(error);
        this.$swal("Error!", "Something went wrong", "error");
      }
    },
    openTrackingModal(id) {
      this.selectedOtherPaymentId = id;
      this.openTrackingOtherPayment = true;
    },
    closeTrackingModal() {
      this.selectedOtherPaymentId = null;
      this.openTrackingOtherPayment = false;
    },
  },
};
</script>

<style>
</style>