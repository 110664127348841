export default [
  {
    key: "client_name",
    label: "Name",
    sortable: true,
    visible: true,
  },
  {
    key: "account",
    label: "Account",
    sortable: true,
    visible: true,
  },
  {
    key: "date",
    label: "Date",
    sortable: true,
    visible: true,
  },
  {
    key: "amount",
    label: "Amount",
    sortable: true,
    visible: true,
  },
  {
    key: "transaction_id",
    label: "Transaction ID",
    sortable: true,
    visible: true,
  },
  {
    key: "evidence",
    label: "Evidence",
    sortable: true,
    visible: true,
  },
  {
    key: "requested_by",
    label: "Requested By",
    sortable: true,
    visible: true,
  },
  {
    key: "evaluated_by",
    label: "Approved By",
    sortable: true,
    visible: true,
  },
  {
    key: "actions",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
]
