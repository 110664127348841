<template>
  <b-modal
    ref="trackingOtherPayments"
    title="Tracking Other Payments"
    @hidden="$emit('hidden')"
    size="lg"
  >
  <h1>hola</h1>
   <!-- <b-table>

   </b-table> -->
  </b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal";
import { mapGetters } from "vuex";
import CeOtherPaymentsService from '../other-payments.service'
export default {
  name: "AddOtherCharge",
  mixins: [modalMixin],
  props: {
    otherPaymentId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  data(){
    return{
        otherPaymentsTracking: [],
        fieldsTracking: [
            { key: "status", label: "Status" },
            { key: "created_at", label: "Created At" },
            { key: "updated_at", label: "Updated At" },
            { key: "created_by", label: "Created By" },
            { key: "updated_by", label: "Updated By" },
            { key: "comments", label: "Comments" },
        ]
    }
  },
  mounted() {
    this.toggleModal("trackingOtherPayments");
    this.getCeOtherPaymentsTracking();
  },
  methods:{
    async getCeOtherPaymentsTracking(){
        const response = await CeOtherPaymentsService.getCeOtherPaymentsTracking({other_payment_id: this.otherPaymentId});
        this.otherPaymentsTracking = response.data;
    }
  }
};
</script>

<style>
</style>